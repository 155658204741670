function reportIssue  () {
    let panel = $("#modalPanelID").val();
    let issues = $("#modalIssue").val();
    let issuedata = {
        gp_panel_id: panel,
        issue: issues,
        user: localStorage.getItem('userEmail')
    };
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl +"api/issuesubmit2",
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": issuedata
    };
    $.ajax(settings).done(function (response) {
        // console.log(response)
        $("#modalIssue").val('');
    });
}
// Example starter JavaScript for disabling form submissions if there are invalid fields
function validate () {
    'use strict';
    window.addEventListener('load', function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        const validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('click', function (event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    if (form.checkValidity() === true) {
                        reportIssue();
                    }
                    form.classList.add('was-validated')
                },
                false);
        });
    }, false);
}
validate();